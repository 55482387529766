import axios from "axios";
import { blendModuleAPI } from "../config";
export default {
  async getItemName(body, color, leaf_appearance, leaf_size, taste) {
    return axios
      .get(
        `${blendModuleAPI}/v1/BlendFactor/GetItemName?LeafSize=${leaf_size}&Appearence=${leaf_appearance}&Color=${color}&Body=${body}&Taste=${taste}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getBlendfactor() {
    let leaf_size = [];
    let body = [];
    let color = [];
    let leaf_appearance = [];
    let taste = [];
    let result = [];
    return axios
      .get(`${blendModuleAPI}/v1/BlendFactor/GetFactors`)
      .then((res) => {
        result = res.data.data;

        leaf_size = result.filter((el) => el.blendFactorName == "Leaf Size");
        body = result.filter((el) => el.blendFactorName == "Body");
        color = result.filter((el) => el.blendFactorName == "Color");
        leaf_appearance = result.filter((el) => el.blendFactorName == "Appearence");
        taste = result.filter((el) => el.blendFactorName == "Taste");

        return { leaf_size, body, color, leaf_appearance, taste };
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
