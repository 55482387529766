<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-title class="mt-5 mb-5">
        <v-btn
          elevation="0"
          :ripple="false"
          height="46"
          dark
          right
          absolute
          @click="submit"
          class="
            font-weight-bold
            text-capitalize
            btn-ls btn-primary
            bg-success
            py-3
            px-6
            mb-5
            mt-5
          "
          >Submit
        </v-btn>
      </v-card-title>
      <!-- v-show="showTable" -->
      <v-card-text class="px-0 py-0" id="scroll_1">
        <ag-grid-vue
          v-model="tastingData"
          rowHeight="40"
          style="height: 500px"
          class="ag-theme-alpine"
          @grid-ready="onGridReady"
          :rowData="tableData"
          :columnDefs="headers"
          :getRowId="getRowId"
          :tabToNextCell="tabToNextCell"
          :defaultColDef="defaultColDef"
        >
        </ag-grid-vue>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col offset-md="5"
            ><v-btn
              elevation="0"
              :ripple="false"
              height="46"
              dark
              @click="submit"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
                mb-5
              "
              >Submit
            </v-btn></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <!-- Save Dialogue -->
    <v-dialog v-model="dialogue" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Do you want to Save?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="noSave"
            elevation="0"
            :ripple="false"
            height="46"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >No</v-btn
          >

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="46"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <!--  
 
    tooltipShowDelay="0"
    tooltipHideDelay="2000" -->
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import blend from "../../api/blend-api";
export default {
  components: {
    AgGridVue,
  },
  props: ["tableData", "validationData"],
  created() {
    this.tastingData = this.tableData;

    this.getRowId = (params) => {
      return params.data.id;
    };
  },
  data() {
    return {
      dialogue: false,
      tastingData: [],
      getRowId: null,
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        suppressMovable: true,
        sortable: true,
        width: 120,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filterParams: { suppressAndOrCondition: true },
        cellStyle: { "border-right-color": "#e2e2e2" },
      },
      headers: [
        {
          headerName: "Lot No",
          field: "lot_no",

          suppressMovable: true,
        },
        {
          headerName: "Item Name",
          field: "item_name",

          suppressMovable: true,
        },

        // {
        //   field: "leaf_appearance",
        //   tooltipField: "leaf_appearance",
        //   maxWidth: 20,
        //   headerName: "*",
        //   suppressMovable: true,

        //   tooltipComponent: `ToolTip`,
        //   tooltipComponentParams: {
        //     message:
        //       "Leaf Appearance Should less than " +
        //       this.leaf_appearance +
        //       " and above 0",
        //   },
        //   cellRenderer: (params) =>
        //     isNaN(parseInt(params.value)) ||
        //     parseInt(params.value) > this.leaf_appearance ||
        //     parseInt(params.value) <= 0
        //       ? `<i class="fa fa-times" style="color:red;" aria-hidden="true"></i> `
        //       : "",
        // },
        {
          headerName: "Leaf Appearance",
          field: "leaf_appearance",
          editable: true,
          suppressMovable: true,
          cellStyle: (params) => {
            if (
              params.value &&
              (isNaN(parseInt(params.value)) ||
                parseInt(params.value) > this.validationData.leaf_appearance ||
                parseInt(params.value) <= 0)
            ) {
              return {
                backgroundColor: "pink",
                color: "black",
                "border-right-color": "#e2e2e2",
              };
            }
            return {
              backgroundColor: "white",
              color: "black",
              "border-right-color": "#e2e2e2",
            };
          },
          onCellValueChanged: async (event) => {
            await this.leafAppearanceValCheck(event);
          },
        },

        {
          headerName: "Leaf Size",
          field: "leaf_size",

          editable: true,
          suppressMovable: true,
          cellStyle: (params) => {
            if (
              params.value &&
              (isNaN(parseInt(params.value)) ||
                parseInt(params.value) > this.validationData.leaf_size ||
                parseInt(params.value) <= 0)
            ) {
              return {
                backgroundColor: "pink",
                color: "black",
                "border-right-color": "#e2e2e2",
              };
            }
            return {
              backgroundColor: "white",
              color: "black",
              "border-right-color": "#e2e2e2",
            };
          },
          onCellValueChanged: async (event) => {
            await this.leafSizeValCheck(event);
          },
        },

        {
          headerName: "Color",
          field: "color",

          editable: true,
          suppressMovable: true,
          cellStyle: (params) => {
            if (
              params.value &&
              (isNaN(parseInt(params.value)) ||
                parseInt(params.value) > this.validationData.color ||
                parseInt(params.value) <= 0)
            ) {
              return {
                backgroundColor: "pink",
                color: "black",
                "border-right-color": "#e2e2e2",
              };
            }
            return {
              backgroundColor: "white",
              color: "black",
              "border-right-color": "#e2e2e2",
            };
          },
          onCellValueChanged: async (event) => {
            await this.colorValCheck(event);
          },
        },

        {
          headerName: "Body",
          field: "body",

          editable: true,
          suppressMovable: true,
          cellStyle: (params) => {
            if (
              params.value &&
              (isNaN(parseInt(params.value)) ||
                parseInt(params.value) > this.validationData.body ||
                parseInt(params.value) <= 0)
            ) {
              return {
                backgroundColor: "pink",
                color: "black",
                "border-right-color": "#e2e2e2",
              };
            }
            return {
              backgroundColor: "white",
              color: "black",
              "border-right-color": "#e2e2e2",
            };
          },
          onCellValueChanged: async (event) => {
            await this.bodyValCheck(event);
          },
        },

        {
          headerName: "Taste",
          field: "taste",

          editable: true,
          suppressMovable: true,
          cellStyle: (params) => {
            if (
              params.value &&
              (isNaN(parseInt(params.value)) ||
                parseInt(params.value) > this.validationData.taste ||
                parseInt(params.value) <= 0)
            ) {
              return {
                backgroundColor: "pink",
                color: "black",
                "border-right-color": "#e2e2e2",
              };
            }
            return {
              backgroundColor: "white",
              color: "black",
              "border-right-color": "#e2e2e2",
            };
          },
          onCellValueChanged: async (event) => {
            await this.tasteValCheck(event);
          },
        },

        {
          headerName: "Comment",
          field: "comment",

          editable: true,
          suppressMovable: true,
        },
        {
          headerName: "Price Valuation",
          field: "price_valuation",

          editable: true,
          suppressMovable: true,
        },
        {
          headerName: "Special Marking",
          field: "special_marking",

          editable: true,
          suppressMovable: true,

          onCellValueChanged: async (event) => {
            await this.valChange(event);
          },
        },
      ],
    };
  },
  methods: {
    // Validation

    async leafAppearanceValCheck(event) {
      let rowNode = this.gridApi.getRowNode(event.data.id);

      let rowData = rowNode.data;

      try {
        if (
          rowData.leaf_appearance &&
          isNaN(parseInt(rowData.leaf_appearance))
        ) {
          throw "Invalid Leaf Appearance value";
        }
        if (
          rowData.leaf_appearance &&
          (parseInt(rowData.leaf_appearance) >
            this.validationData.leaf_appearance ||
            parseInt(rowData.leaf_appearance) == 0)
        ) {
          throw (
            "Leaf Appearance Should less than " +
            this.validationData.leaf_appearance +
            " and above 0"
          );
        }
        rowData.appearanceValidation = true;
        rowNode.setData({ ...rowData });
        await this.valChange(event);
      } catch (error) {
        rowData.appearanceValidation = false;
        rowNode.setData({ ...rowData });
        this.resetItemName(rowNode);
      }
    },

    async leafSizeValCheck(event) {
      let rowNode = this.gridApi.getRowNode(event.data.id);
      let rowData = rowNode.data;
      try {
        if (rowData.leaf_size && isNaN(parseInt(rowData.leaf_size))) {
          throw "Invalid Leaf Size value";
        }
        if (
          rowData.leaf_size &&
          (parseInt(rowData.leaf_size) > this.validationData.leaf_size ||
            parseInt(rowData.leaf_size) == 0)
        ) {
          throw (
            "Leaf Size Should less than " +
            this.validationData.leaf_size +
            " and above 0"
          );
        }
        rowData.leafSizeValidation = true;
        rowNode.setData({ ...rowData });
        await this.valChange(event);
      } catch (error) {
        rowData.leafSizeValidation = false;
        rowNode.setData({ ...rowData });
        this.resetItemName(rowNode);
      }
    },

    async colorValCheck(event) {
      let rowNode = this.gridApi.getRowNode(event.data.id);
      let rowData = rowNode.data;
      try {
        if (rowData.color && isNaN(parseInt(rowData.color))) {
          throw "Invalid Color value";
        }
        if (
          rowData.color &&
          (parseInt(rowData.color) > this.validationData.color ||
            parseInt(rowData.color) == 0)
        ) {
          throw (
            "Color Should less than " +
            this.validationData.color +
            " and above 0"
          );
        }
        rowData.colorValidation = true;
        rowNode.setData({ ...rowData });
        await this.valChange(event);
      } catch (error) {
        rowData.colorValidation = false;
        rowNode.setData({ ...rowData });
        this.resetItemName(rowNode);
      }
    },

    async bodyValCheck(event) {
      let rowNode = this.gridApi.getRowNode(event.data.id);
      let rowData = rowNode.data;
      try {
        if (rowData.body && isNaN(parseInt(rowData.body))) {
          throw "Invalid Body value";
        }
        if (
          rowData.body &&
          (parseInt(rowData.body) > this.validationData.body ||
            parseInt(rowData.body) == 0)
        ) {
          throw (
            "Body Should less than " + this.validationData.body + " and above 0"
          );
        }
        rowData.bodyValidation = true;
        rowNode.setData({ ...rowData });
        await this.valChange(event);
      } catch (error) {
        rowData.bodyValidation = false;
        rowNode.setData({ ...rowData });
        this.resetItemName(rowNode);
      }
    },

    async tasteValCheck(event) {
      let rowNode = this.gridApi.getRowNode(event.data.id);
      let rowData = rowNode.data;
      try {
        if (rowData.taste && isNaN(parseInt(rowData.taste))) {
          throw "Invalid Taste value";
        }
        if (
          rowData.taste &&
          (parseInt(rowData.taste) > this.validationData.taste ||
            parseInt(rowData.taste) == 0)
        ) {
          throw (
            "Taste Should less than " +
            this.validationData.taste +
            " and above 0"
          );
        }
        rowData.tasteValidation = true;
        rowNode.setData({ ...rowData });
        await this.valChange(event);
      } catch (error) {
        rowData.tasteValidation = false;
        rowNode.setData({ ...rowData });
        this.resetItemName(rowNode);
      }
    },

    //

    async valChange(event) {
      let rowNode = this.gridApi.getRowNode(event.data.id);
      let rowData = rowNode.data;

      if (
        rowData.leaf_appearance &&
        rowData.leaf_size &&
        rowData.color &&
        rowData.body &&
        rowData.taste
      ) {
        try {
          blend
            .getItemName(
              rowData.body,
              rowData.color,
              rowData.leaf_appearance,
              rowData.leaf_size,
              rowData.taste
            )
            .then((res) => {
              rowData.item_name_1 = res.data.name;
              rowData.item_name = this.setItemName(rowData);
              rowNode.setData({ ...rowData });
            });
        } catch (error) {
          this.resetItemName(rowNode);
          this.showErrorAlert(error);
        }
      }
    },

    //

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    //

    tabToNextCell(params) {
      const previousCell = params.previousCellPosition;
      const NextCell = params.nextCellPosition;
      let nextRowIndex = previousCell.rowIndex + 1;
      let renderedRowCount = params.api.getModel().getRowCount();
      console.log({
        rowIndex: nextRowIndex,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned,
      });
      if (NextCell.column.colId != "special_marking") return NextCell;
      if (nextRowIndex >= renderedRowCount) {
        return null;
      } // returning null means don't navigate
      let firstCol = params.columnApi.getAllDisplayedColumns()[0];
      return {
        rowIndex: nextRowIndex,
        column: firstCol,
        rowPinned: previousCell.rowPinned,
      };
    },
    //
    resetItemName(rowNode) {
      let rowData = rowNode.data;
      rowData.item_name = null;
      rowNode.setData({ ...rowData });
    },
    //
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
    },
    // //
    save() {
      let req = [];
      this.dialogue = false;
      this.tastingData.forEach((item) => {
        if (item.item_name) {
          let obj = {
            id: item.id,
            lot_no: item.lot_no,
            item_name: item.item_name,
            price_valuation: item.price_valuation,
            leaf_appearance: item.leaf_appearance,
            leaf_size: item.leaf_size,
            color: item.color,
            body: item.body,
            taste: item.taste,
            comment: item.comment,
            special_marking: item.special_marking,
          };

          req.push(obj);
        }
      });

      console.log("REQ", req);
      this.$emit("submit", req);
    },
    // //

    submit() {
      let res = this.tastingData.some(
        (el) =>
          el.appearanceValidation == false ||
          el.leafSizeValidation == false ||
          el.colorValidation == false ||
          el.bodyValidation == false ||
          el.tasteValidation == false
      );

      if (!res) {
        this.dialogue = true;
      } else {
        this.showErrorAlert("Please Check Tasting Data.");
      }
    },
    //
    setItemName(item) {
      if (item.special_marking) {
        return `${item.item_name_1}${item.special_marking}`;
      } else {
        return item.item_name_1;
      }
    },
    //
    noSave() {
      this.dialogue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.ag-theme-alpine {
  /* disable all borders */
  --ag-borders: 1px solid;
  /* then add back a border between rows */
  // --ag-borders-row: dashed 1px;
  // --ag-row-border-color: rgb(150, 150, 200);
  --ag-value-change-value-highlight-background-color: pink;
}
</style>
